<template>
  <v-container fluid v-if='LOGGED_IN' id='page' class='pt-0 overflow-y-auto'>

    <v-row>
      <v-col>
        <v-btn class='primary' @click='clickNewAccount'><v-icon>mdi-plus</v-icon>New Account</v-btn>
      </v-col>
    </v-row>

    <v-sheet rounded elevation="2" class='white rounded mt-5' style='max-width:1300px;overflow:hidden;'>
      <v-data-table
        :headers='headers'
        :items='accounts'
        class='transparent'
      >
      <template v-slot:item.updateButton="{ item }">
        <v-icon class='primary--text' @click='clickUpdate(item)'>mdi-pencil</v-icon>
      </template>
    </v-data-table>
  </v-sheet>


  <v-dialog v-model="showEditForm" max-width="500" persistent>
    <v-card elevation="20" :disabled='editFormLoading'>
      <v-card-title class="text-h6 primary white--text py-3">
        {{editOKButton}} Account
      </v-card-title>
      <v-card-text class='pb-0 pt-8 text-subtitle-2'>
        <v-text-field v-model='editAccountName' rounded outlined dense label='Account Name' />
        <v-text-field v-model='editLabcode' rounded outlined dense label='Labcode' />
        <v-select v-model='editModuleGroup' rounded outlined dense label='Module Group' :items='moduleGroups' item-text='title' item-value='xid' />
        <v-text-field v-model='editUploadURI' rounded outlined dense label='File Upload URI' />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary darken-1" @click='closeEditForm'>Cancel</v-btn>
        <v-btn color="primary darken-1" @click='clickOK'>{{editOKButton}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  </v-container>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Accounts',

  components: {
  },

  data: () => ({
    headers: [
      { text: 'Account', value: 'business' },
      { text: 'Labcode', value: 'labdata.labcode' },
      { text: 'Module Group', value: 'permission.title' },
      { text: 'Update', value: 'updateButton' },
    ],
    accounts: [],
    showEditForm: false,
    editAccountName: "",
    editLabcode: null,
    editModuleGroup: null,
    editUploadURI: null,
    editAccountId: null,
    moduleGroups: [],
    editOKButton: "Save",
    editFormLoading: false,
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN', 'TOKEN']),
  },

  methods:{
    ...mapActions('UI', ['SHOW_NAVBARS', 'NOTIFY', 'SET_PAGE_TITLE', 'PROMPT']),
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('API', ['API']),

    fetchAccounts(){
      return this.API({ endpoint: 'core/auth/accounts/list', data: {
          token: this.TOKEN,
      }})
      .then(accounts => {
        this.accounts = accounts;
      });
    },

    loadPermissionGroups(){
      return this.API({ endpoint: 'core/auth/permissions', data: { token: this.TOKEN } })
      .then((data)=> {
        this.moduleGroups = data;
      });
    },

    clickNewAccount(){
      this.editOKButton = "Create";
      this.showEditForm = true;
    },

    closeEditForm(){
      this.showEditForm = false;
      this.editAccountName = "";
      this.editAccountId = null;
      this.editModuleGroup = null;
      this.editLabcode = null;
      this.editUploadURI = null;
    },

    clickUpdate(item){
      this.editOKButton = "Update";
      this.editAccountId = item.xid;
      this.editAccountName = item.business;
      this.editModuleGroup = item.permission.xid;
      this.editLabcode = item.labdata?.labcode ?? "";
      this.editUploadURI = item.labdata?.upload_uri ?? "";
      this.showEditForm = true;
    },

    clickOK(){
      this.editFormLoading = true;
      if(this.editAccountId){
        //update
        this.API({ endpoint: 'core/auth/accounts/update', data: {
          token: this.TOKEN,
          account: this.editAccountId,
          name: this.editAccountName,
          labcode: this.editLabcode,
          permission: this.editModuleGroup,
          upload_uri: this.editUploadURI,
        }}).then(()=>{
          this.closeEditForm();
          this.fetchAccounts();
        }).finally(()=>{ this.editFormLoading = false; });
      }
      else{
        //create
        this.API({ endpoint: 'core/auth/accounts/create', data: {
          token: this.TOKEN,
          name: this.editAccountName,
          permission: this.editModuleGroup,
          labcode: this.editLabcode,
          upload_uri: this.editUploadURI,
        }}).then(()=>{
          this.closeEditForm();
          this.fetchAccounts();
        }).finally(()=>{ this.editFormLoading = false; });
      }
    }

  },

  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS()
      this.fetchAccounts()
      .then(this.loadPermissionGroups);
    });
  },

};
</script>


<style lang="scss" scoped>
@import "@/assets/common";

#page{
  .v-data-table::v-deep{
    position: relative;
    thead{
      background-color: $primary;
      th{
        color: white;
      }
    }
  }
}

</style>
