var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.LOGGED_IN ? _c('v-container', {
    staticClass: "pt-0 overflow-y-auto",
    attrs: {
      "fluid": "",
      "id": "page"
    }
  }, [_c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.clickNewAccount
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v("New Account")], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "white rounded mt-5",
    staticStyle: {
      "max-width": "1300px",
      "overflow": "hidden"
    },
    attrs: {
      "rounded": "",
      "elevation": "2"
    }
  }, [_c('v-data-table', {
    staticClass: "transparent",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.accounts
    },
    scopedSlots: _vm._u([{
      key: "item.updateButton",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-icon', {
          staticClass: "primary--text",
          on: {
            "click": function click($event) {
              return _vm.clickUpdate(item);
            }
          }
        }, [_vm._v("mdi-pencil")])];
      }
    }], null, false, 1562233719)
  })], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500",
      "persistent": ""
    },
    model: {
      value: _vm.showEditForm,
      callback: function callback($$v) {
        _vm.showEditForm = $$v;
      },
      expression: "showEditForm"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20",
      "disabled": _vm.editFormLoading
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 primary white--text py-3"
  }, [_vm._v(" " + _vm._s(_vm.editOKButton) + " Account ")]), _c('v-card-text', {
    staticClass: "pb-0 pt-8 text-subtitle-2"
  }, [_c('v-text-field', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "label": "Account Name"
    },
    model: {
      value: _vm.editAccountName,
      callback: function callback($$v) {
        _vm.editAccountName = $$v;
      },
      expression: "editAccountName"
    }
  }), _c('v-text-field', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "label": "Labcode"
    },
    model: {
      value: _vm.editLabcode,
      callback: function callback($$v) {
        _vm.editLabcode = $$v;
      },
      expression: "editLabcode"
    }
  }), _c('v-select', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "label": "Module Group",
      "items": _vm.moduleGroups,
      "item-text": "title",
      "item-value": "xid"
    },
    model: {
      value: _vm.editModuleGroup,
      callback: function callback($$v) {
        _vm.editModuleGroup = $$v;
      },
      expression: "editModuleGroup"
    }
  }), _c('v-text-field', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "label": "File Upload URI"
    },
    model: {
      value: _vm.editUploadURI,
      callback: function callback($$v) {
        _vm.editUploadURI = $$v;
      },
      expression: "editUploadURI"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1"
    },
    on: {
      "click": _vm.closeEditForm
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "color": "primary darken-1"
    },
    on: {
      "click": _vm.clickOK
    }
  }, [_vm._v(_vm._s(_vm.editOKButton))])], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }